import { RentalApplicationTransUnion } from '~/pages/rental-application/screening/RentalApplicationTransUnion';

const TransUnionConnectionPage = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <RentalApplicationTransUnion />
    </div>
  );
};

export default TransUnionConnectionPage;
