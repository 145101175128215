import { RENTAL_APPLICATION_NOT_FOUND } from '~/assets/strings';
import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class StartRentalApplicationScreeningUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    const appId = credentials.id;
    const appPassword = credentials.password;
    if (!appId || !appPassword) throw new Error(RENTAL_APPLICATION_NOT_FOUND);
    this.getState().user.rentalApplication.transUnionScreening = await rentalApplicationGateway.startTransUnionScreening(credentials);
    this.navigate(`/rental-applications/${credentials.id}/${credentials.password}/questionnaire`);
  }
}
